import uniqid from 'uniqid'
import './Skills.css'

const Skills = ({ skills }) => {
  if (!skills.length) return null

  return (
    <div className='about__details'>
      <section className='section skills' id='skills'>
        <h2 className='section__title'>Skills</h2>
        <ul className='skills__list'>
          {skills.map((skill) => (
            <li key={uniqid()} className='skills__list-item btn btn--plain'>
              {skill}
            </li>
          ))}
        </ul>
      </section>
    </div>
  )
}

export default Skills
