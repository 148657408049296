// import GitHubIcon from '@material-ui/icons/GitHub'
// import LinkedInIcon from '@material-ui/icons/LinkedIn'
import {
  FaUser,
  FaBriefcase,
  FaUniversity,
  FaEnvelope,
  FaMapMarkerAlt,
  FaGithub,
  FaLinkedin,
  FaTwitter,
  FaGoogle,
  FaMedium,
} from 'react-icons/fa'
import './About.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser'
import dpSrc from '../../static/photo.jpeg'

const About = ({ about }) => {
  const {
    name,
    role,
    description,
    resume,
    social,
    shortbio,
    study,
    email,
    where,
  } = about
  const yourImageURL = dpSrc

  return (
    <div className='about__details'>
      {name && (
        <h1>
          Hi, I am <span className='about__name'>Soumyajit.</span>
        </h1>
      )}

      {role && <h2 className='about__role'>A {role}.</h2>}

      <p className='about__desc'>
        {parse(String(description)) && parse(String(description))}
      </p>
    </div>
  )
}

export default About
