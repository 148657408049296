import './Blogs.css'
import { useState } from 'react'

const Blogs = ({ blogs }) => {
  const [visibleBlogs, setVisibleBlogs] = useState(3)

  // Function to load 5 more blogs
  const loadMoreBlogs = () => {
    setVisibleBlogs((prevVisible) => prevVisible + 3)
  }

  return (
    <div className='about__details'>
      <section id='blogs' className='section blogs'>
        <h2 className='section__title'>Featured Blogs</h2>
        <ul className='blogs__list'>
          {blogs.slice(0, visibleBlogs).map((blog) => (
            <li key={blog.title} className='blog__item'>
              <div className='blog__image-container'>
                <img
                  src={blog.image}
                  alt={blog.title}
                  className='blog__image'
                />
              </div>
              <div className='blog__content'>
                <a
                  href={blog.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='blog__title'
                >
                  {blog.title}
                </a>
                <p className='blog__date'>{blog.date}</p>
                <p className='blog__description'>{blog.description}</p>
              </div>
            </li>
          ))}
        </ul>
        {visibleBlogs < blogs.length && (
          <button
            type='button'
            className='load-more-btn'
            onClick={loadMoreBlogs}
          >
            Load More
          </button>
        )}
      </section>
    </div>
  )
}

export default Blogs
