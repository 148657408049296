import React from 'react'
import './Loader.css' // Create a CSS file for the loader

const Loader = () => (
  <div className='loader'>
    <div className='spinner' />
  </div>
)

export default Loader
