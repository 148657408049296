import './Experience.css'

import uniqid from 'uniqid'

const Experience = ({ experiences }) => (
  <section id='experience' className='section experience'>
    <h2 className='section__title'>Experience</h2>
    <div className='timeline'>
      {experiences.map((exp) => (
        <div key={uniqid()} className='timeline__item'>
          <div className='timeline__marker' />
          <div className='timeline__content'>
            <h3>{exp.title}</h3>
            <p>
              <strong>{exp.organization}</strong>
            </p>
            <p>{exp.duration}</p>
            {/* <p>{exp.description}</p> */}
            <ul>
              {exp.keyPoints.map((point) => (
                <li key={uniqid()}>{point}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      <div className='timeline__line' />
    </div>
  </section>
)

export default Experience
