import { useContext, useState } from 'react'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { ThemeContext } from '../../contexts/theme'
import './Navbar.css'

const Navbar = ({ activeTab, navSelect }) => {
  const [{ themeName, toggleTheme }] = useContext(ThemeContext)
  const [showNavList, setShowNavList] = useState(false)

  const toggleNavList = (tab) => {
    setShowNavList(!showNavList)
    navSelect(tab)
  }

  const isActive = (tab) => (activeTab === tab ? 'nav__list-item--active' : '')

  return (
    <nav className='center nav'>
      <ul
        style={{ display: showNavList ? 'flex' : null }}
        className='nav__list'
      >
        <li className={`nav__list-item ${isActive('about')}`}>
          <a
            href='#about'
            onClick={() => toggleNavList('about')}
            className='link link--nav'
          >
            About
          </a>
        </li>

        <li className={`nav__list-item ${isActive('skills')}`}>
          <a
            href='#skills'
            onClick={() => toggleNavList('skills')}
            className='link link--nav'
          >
            Skills
          </a>
        </li>

        <li className={`nav__list-item ${isActive('publications')}`}>
          <a
            href='#publications'
            onClick={() => toggleNavList('publications')}
            className='link link--nav'
          >
            Publications
          </a>
        </li>

        <li className={`nav__list-item ${isActive('experience')}`}>
          <a
            href='#experience'
            onClick={() => toggleNavList('experience')}
            className='link link--nav'
          >
            Experience
          </a>
        </li>

        <li className={`nav__list-item ${isActive('blogs')}`}>
          <a
            href='#blogs'
            onClick={() => toggleNavList('blogs')}
            className='link link--nav'
          >
            Blogs
          </a>
        </li>
        <li className={`nav__list-item ${isActive('projects')}`}>
          <a
            href='#projects'
            onClick={() => toggleNavList('projects')}
            className='link link--nav'
          >
            Projects
          </a>
        </li>

        {/* {contact.email ? (
          <li className={`nav__list-item ${isActive('contact')}`}>
            <a
              href='#contact'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Contact
            </a>
          </li>
        ) : null}         */}
      </ul>

      <button
        type='button'
        onClick={toggleTheme}
        className='btn btn--icon nav__theme'
        aria-label='toggle theme'
      >
        {themeName === 'dark' ? <WbSunnyRoundedIcon /> : <Brightness2Icon />}
      </button>

      <button
        type='button'
        onClick={toggleNavList}
        className='btn btn--icon nav__hamburger'
        aria-label='toggle navigation'
      >
        {showNavList ? <CloseIcon /> : <MenuIcon />}
      </button>
    </nav>
  )
}

export default Navbar
