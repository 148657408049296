import { useContext } from 'react'
import { ThemeContext } from './contexts/theme'
import Header from './components/Header/Header'
// import About from './components/About/About'
import Main from './components/Main/Main'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Footer from './components/Footer/Footer'
import './App.css'

const App = () => {
  const [{ themeName }] = useContext(ThemeContext)

  return (
    <div id='top' className={`${themeName} app`}>
      {/* <Header /> */}

      <main>
        <Main />
        {/* <Projects />
        <Skills />
        <Contact /> */}
      </main>

      <ScrollToTop />
      <Footer />
    </div>
  )
}

export default App
