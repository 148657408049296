const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://soumyajitdatta.com',
  title: 'SD.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Soumyajit Datta',
  role: 'CS PhD Student',
  description:
    'I am a first-year PhD student and Graduate Research Assistant at Rochester Institute of Technology under <a href="https://www.se.rit.edu/~axkvse/" target="_blank"><u>Dr. Ashique KhudaBukhsh</u></a>, specializing in <a href="#"><i>Computational Social Science and Linguistics </i></a>. My research revolves around leveraging advanced Natural Language Processing (NLP) and Computer Vision techniques to extract valuable social insights. Additionally, I work with Generative AI, with a focus on <a href="#"><i>AI for social impact</i></a> and <a href="#"><i>Large Language Model(LLM) safety</i></a>. Outside of research, I enjoy playing chess and the piano, always seeking new intellectual and creative challenges. Occasionally, I write blogs, compete on Kaggle, or dive into discovering fascinating facts about the universe.',
  resume: 'https://soumyajitdatta.com',
  shortbio: 'PhD Student',
  study: 'Rochester Institute of Technology',
  email: 'sd3528[at]rit[dot]edu',
  where: 'New York, USA',
  social: {
    linkedin: 'https://linkedin.com/in/codehackerone',
    github: 'https://github.com/codehackerone',
    google_scholar:
      'https://scholar.google.com/citations?user=haI2B0QAAAAJ&hl=en',
    twitter: 'https://x.com/soumyajit_dt',
    medium: 'https://medium.com/@soumyajitdatta123',
    hashnode: 'https://hashnode.com',
    kaggle: 'https://kaggle.com',
  },
}

const publications = [
  {
    year: '2024',
    authors:
      'Somonnoy Banerjee, Sujan Dutta, <b><u>Soumyajit Datta</u></b>, Ashiqur R. KhudaBukhsh',
    title:
      'Gender Representation and Bias in Indian Civil Service Mock Interviews',
    link: 'https://arxiv.org/abs/2409.12194',
    conference: 'arXiv',
  },
  {
    year: '2024',
    authors:
      'MTA Chowdhury, <b><u>Soumyajit Datta</u></b>, Naveen Sharma, Ashiqur R. KhudaBukhsh',
    title:
      'Infrastructure Ombudsman: Mining Future Failure Concerns from Structural Disaster Response',
    link: 'https://dl.acm.org/doi/abs/10.1145/3589334.3648153',
    conference: "WWW '24: Proceedings of the ACM Web Conference 2024",
  },

  // {
  //   year: '2024',
  //   authors: 'S. Datta.',
  //   title: 'Nirnyaaak',
  //   link: 'https://example.com/paper2',
  //   conference: 'IEEE',
  // },
  // Add more papers here
]

const blogs = [
  {
    title: 'The Evolution of LLM Agents — From Text to Intelligent Action',
    date: 'Oct 2, 2024',
    description:
      'Let’s explore the fascinating journey of LLM agents, along with a fun nod to The Big Bang Theory because, after all, Sheldon Cooper would love talking about AI as much as string theory.',
    link: 'https://medium.com/@soumyajitdatta123/the-evolution-of-llm-agents-from-text-to-intelligent-action-0958e3c41f14',
    image:
      'https://miro.medium.com/v2/resize:fit:720/format:webp/1*3qndlMXbsO7iJgrQn92Ptw.png', // Replace with your image URL
  },
  {
    title:
      'Chain of Thought: Unraveling the Power and Limitations of Reasoning in Large Language Models',
    date: 'Sep 29, 2024',
    description:
      'In this post, we’ll explore how reasoning works in LLMs, the strategies that can enhance their reasoning abilities, and the key limitations they still face.',
    link: 'https://medium.com/@soumyajitdatta123/chain-of-thought-unraveling-the-power-and-limitations-of-reasoning-in-large-language-models-1e4228eb8803',
    image:
      'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*EKqmeeKRK-84M_yrsPmOsA.jpeg',
  },
  {
    title: 'The Magic of Graph Neural Networks: Jab Neural Networks Met Graphs',
    date: 'Apr 19, 2023',
    description:
      'A comprehensive guide to Graph Neural Networks (GNNs) and their applications in various domains.',
    link: 'https://medium.com/@soumyajitdatta123/the-magic-of-graph-neural-networks-jab-neural-networks-met-graphs-aa181468795f',
    image:
      'https://miro.medium.com/v2/resize:fit:720/format:webp/1*pj_qRWctMpYLBygPrs8AMQ.jpeg',
  },
  {
    title: 'Using Custom Datasets to train DETR for object detection',
    date: 'April 14, 2023',
    description:
      'How to train the DETR model for object detection using custom datasets and PyTorch.',
    link: 'https://medium.com/@soumyajitdatta123/using-custom-datasets-to-train-detr-for-object-detection-75a6426b3f4e',
    image:
      'https://miro.medium.com/v2/resize:fit:640/format:webp/1*u-qoEPM86RZGNqZHqCUQ3A.jpeg',
  },
  {
    title:
      'Introducing Nirnayaak: The AI behind Revolutionary Web Application Simplifying Legal Research',
    date: 'Apr 6, 2023',
    description:
      'Are you tired of sifting through piles of legal documents and case files, trying to find the information you need? Do you feel like you need a degree in law just to understand the jargon and legalese? Well, fret not, my friend! Nirnayaak is here to save the day!',
    link: 'https://soumyajitdatta.hashnode.dev/introducing-nirnayaak-the-ai-behind-revolutionary-web-application-simplifying-legal-research',
    image:
      'https://cdn.hashnode.com/res/hashnode/image/upload/v1680803214692/33ae947e-753c-4f3c-bef6-a2c9aa6dd715.webp?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp',
  },
]

const experience = [
  // {
  //   title: 'Graduate Research Assistant',
  //   organization: 'Rochester Institute of Technology (RIT)',
  //   duration: 'Sep 2024 - Present',
  //   description:
  //     'Working on extracting social insights using NLP and computer vision techniques.',
  //   keyPoints: [
  //     'Focus on AI safety and NLP projects related to political polarization.',
  //     'Specializing in LLMs and disaster response systems.',
  //   ],
  // },
  {
    title: 'Research Intern',
    organization: 'Rochester Institute of Technology',
    duration: 'Jul 2023 - Jul 2024 · 1 yr 1 mo',
    keyPoints: [
      'Collaborated on disaster response research, creating an infrastructure ombudsman to identify concerns from social web discussions.',
      'Developed a dataset of 2,662 instances from Reddit and YouTube, with robust baselines, enhancing disaster preparedness and infrastructure resilience; accepted at Web ’24 Conference.',
      'Conducted in-depth research on digital discourse polarization, focusing on contentious topics like police, abortion, gun violence, and affirmative action on social media platforms.',
      'Explored societal implications and evaluated text classification accuracy as a quantitative measure of polarization, enhancing the field’s methodology for understanding digital discourse.',
    ],
  },
  {
    title: 'Machine Learning Intern',
    organization: 'Aarish Technologies Inc.',
    duration: 'Jun 2023 - Jul 2024',
    keyPoints: [],
  },
  {
    title: 'Data Science Intern',
    organization: 'Kavida.ai',
    duration: 'Aug 2023 - May 2024',
    keyPoints: [
      'Optimized Supplier Onboarding: Enhanced the supplier onboarding and craft pipelines using multiprocessing, improving efficiency and processing speed.',
      'Risk Analysis Automation: Benchmarked, implemented, and deployed LLM-powered responses for risk analysis, reducing manual verification processes by 70%.',
      'Business Signal Implementation: Developed and deployed an alternative method for adding business signals to articles using a tag mechanism, streamlining content categorization.',
      'Kavida Copilot Enhancement: Benchmarked and introduced two modules for Kavida Copilot in Langchain: Answering In-domain General Knowledge questions and question Classification.',
    ],
  },
  {
    title: 'ML Research Intern, CICPS',
    organization: 'Indian Institute of Technology, Guwahati',
    duration: 'Sep 2022 - Sep 2023',
    keyPoints: [
      'Used Generative Adversarial Networks (GANs) to create image features in breast ultrasound images to improve mAP(mean average precision) scores in object-detection models such as Faster-RCNNs and DETR.',
      'Color restoration of underwater images using super-resolution techniques by Convolutional Attention Modules.',
    ],
  },
  {
    title: 'Machine Learning Intern',
    organization: 'Zuma',
    duration: 'Sep 2022 - Feb 2023',
    keyPoints: [
      'Developed a Paraphrasing Module using GPT-3 improving Kelsey’s conversations, achieving an SSA score of 93.5, and integrated it with the existing DialogManager Module.',
      'Conducted a literature review and implemented the Multi-Label Text Classification using Attention-based Graph Neural Network (MAGNET) paper, built training and test pipelines, resulting in a 6% improvement in results.',
      'Fine-tuned a transformer model to create an Empathy Model for Kelsey (AI Assistant), achieving a test accuracy of 0.81% and created a fastAPI module and exposed a testing endpoint.',
      'Shipped ML Models using Docker Containers in AWS Sagemaker and EC2 instances for production.',
    ],
  },
  {
    title: 'Back End Developer and Project Lead',
    organization: 'Tech Table',
    duration: 'May 2021 - Nov 2021',
    keyPoints: [
      'Backend Web Development in NodeJS with MongoDB, Firebase.',
      'Projects with Google Drive, Sheet and OAuth2 APIs.',
      'Projects with Cloudinary, Twilio APIs, AWS RDS, and Postgres Server.',
      'Frontend Development with React.',
    ],
  },
  {
    title: 'ML Research Intern',
    organization: 'Jadavpur University',
    duration: 'Jul 2022 - Sep 2022',
    keyPoints: [
      'Enhanced the UNET architecture for skin lesion segmentation by incorporating spatial and channel attention mechanisms, resulting in notable performance improvements with IoU and DICE scores greater than most literature benchmarks.',
    ],
  },
  {
    title: 'Machine Learning Intern',
    organization: 'InfyU LABS',
    duration: 'Jun 2022 - Aug 2022',
    keyPoints: [
      'Spectroscopic Data Analysis with Classification and Regression Problems.',
      'Building ML automation applications using StreamLit.',
    ],
  },
  {
    title: 'SDE Intern',
    organization: 'Hooley',
    duration: 'Dec 2021 - Jun 2022',
    keyPoints: [
      'Development of frontend web application in React.',
      'Backend development with NodeJS, MongoDB.',
      'Used AWS S3 buckets, AWS SQS, AWS SES, IAM, and Twilio Messaging APIs.',
    ],
  },
  {
    title: 'Backend Engineer',
    organization: 'Glorio',
    duration: 'May 2021 - May 2022',
    keyPoints: [
      'Development of frontend web application in React and backend development in NodeJS and MongoDB.',
      'Using AWS S3 buckets, AWS SES, AWS IAM, Twilio Messaging APIs.',
    ],
  },
]

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  // {
  //   name: 'Project 1',
  //   description:
  //     'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
  //   stack: ['SASS', 'TypeScript', 'React'],
  //   sourceCode: 'https://github.com',
  //   livePreview: 'https://github.com',
  // },
  // {
  //   name: 'Project 2',
  //   description:
  //     'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
  //   stack: ['SASS', 'TypeScript', 'React'],
  //   sourceCode: 'https://github.com',
  //   livePreview: 'https://github.com',
  // },
  // {
  //   name: 'Project 3',
  //   description:
  //     'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
  //   stack: ['SASS', 'TypeScript', 'React'],
  //   sourceCode: 'https://github.com',
  //   livePreview: 'https://github.com',
  // },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Python',
  'Machine Learning',
  'Deep Learning',
  'Natural Language Processing',
  'Computer Vision',
  'TypeScript',
  'React',
  'Node.js',
  'MongoDB',
  'PyTorch',
  'TensorFlow',
  '[AWS, GCP, Azure]',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'soumyajitdatta123@gmail.com',
}

export default {
  header,
  about,
  projects,
  skills,
  contact,
  experience,
  publications,
  blogs,
}
