// import GitHubIcon from '@material-ui/icons/GitHub'
// import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { useContext, useState, useEffect } from 'react'
import {
  FaUser,
  FaBriefcase,
  FaUniversity,
  FaEnvelope,
  FaMapMarkerAlt,
  FaGithub,
  FaLinkedin,
  FaTwitter,
  FaGoogle,
  FaMedium,
} from 'react-icons/fa'
import P from '../../portfolio'
import './Main.css'
import About from '../About/About'
import Project from '../Projects/Projects'
import Experience from '../Experience/Experience'
import Publications from '../Publications/Publications'
import Skills from '../Skills/Skills'
import Blogs from '../Blogs/Blogs'
import Loader from '../Loader/Loader'
import dpSrc from '../../static/photo.jpeg'
import Navbar from '../Navbar/Navbar'

const Main = () => {
  const yourImageURL = dpSrc
  const [loading, setLoading] = useState(false)

  const [navSelected, setNavSelected] = useState('about')

  const [portfolio, setPortfolio] = useState({})
  const [about, setAbout] = useState({})
  const [header, setHeader] = useState({})

  function fetchData() {
    setLoading(true)
    const data = P
    setPortfolio(data)
    setAbout(data.about)
    setHeader(data.header)

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  useEffect(() => {
    // setLoading(true); // Start loading
    window.scrollTo(0, 0)
    fetchData()
  }, [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div id='content'>
          <header className='header center'>
            <h3>
              {about.homepage ? (
                <a href={about.homepage} className='link'>
                  {header.title}
                </a>
              ) : (
                header.title
              )}
            </h3>
            <Navbar activeTab={navSelected} navSelect={setNavSelected} />
          </header>
          <div className='about-container'>
            <div className='about__image-links'>
              {/* Add your picture here */}
              <img
                src={yourImageURL}
                alt='Your Name'
                className='about__image'
              />

              {/* Personal Details */}
              <ul className='about__details-list'>
                <li>
                  <FaUser /> {about.name}
                </li>
                <li>
                  <FaBriefcase /> {about.shortbio}
                </li>
                <li>
                  <FaUniversity /> {about.study}
                </li>
                <li>
                  <FaEnvelope /> {about.email}
                </li>
                <li>
                  <FaMapMarkerAlt /> {about.where}
                </li>
              </ul>

              {/* Important profile links below the image */}
              <ul className='about__links'>
                {about.social && (
                  <>
                    {about.social.github && (
                      <li>
                        <a
                          target='_blank'
                          href={about.social.github}
                          aria-label='github'
                          className='link link--icon'
                          rel='noreferrer'
                        >
                          <FaGithub /> GitHub
                        </a>
                      </li>
                    )}

                    {about.social.linkedin && (
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href={about.social.linkedin}
                          aria-label='linkedin'
                          className='link link--icon'
                        >
                          <FaLinkedin /> LinkedIn
                        </a>
                      </li>
                    )}

                    {about.social.twitter && (
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href={about.social.twitter}
                          aria-label='twitter'
                          className='link link--icon'
                        >
                          <FaTwitter /> Twitter
                        </a>
                      </li>
                    )}

                    {about.social.google_scholar && (
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href={about.social.google_scholar}
                          aria-label='google scholar'
                          className='link link--icon'
                        >
                          <FaGoogle /> Google Scholar
                        </a>
                      </li>
                    )}

                    {about.social.medium && (
                      <li>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href={about.social.medium}
                          aria-label='medium'
                          className='link link--icon'
                        >
                          <FaMedium /> Medium
                        </a>
                      </li>
                    )}

                    {/* {social.hashnode && (
                  <li>
                    <a
                      href={social.hashnode}
                      aria-label='hashnode'
                      className='link link--icon'
                    >
                      Hashnode
                    </a>
                  </li>
                )} */}
                  </>
                )}
              </ul>
            </div>

            {navSelected === 'about' && <About about={about} />}

            {navSelected === 'experience' && (
              <Experience experiences={portfolio.experience} />
            )}

            {navSelected === 'projects' && (
              <Project projects={portfolio.projects} />
            )}

            {navSelected === 'publications' && (
              <Publications papers={portfolio.publications} />
            )}

            {navSelected === 'skills' && <Skills skills={portfolio.skills} />}

            {navSelected === 'blogs' && <Blogs blogs={portfolio.blogs} />}
          </div>
        </div>
      )}
    </>
  )
}

export default Main
