import './Publications.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser'

const Publications = ({ papers }) => (
  <div className='about__details'>
    <section id='publications' className='section publications'>
      <h2 className='section__title'>Publications</h2>
      <ul className='publications__list'>
        {papers.map((paper) => (
          <li key={paper.title} className='publication__item'>
            <span className='publication__year'>{paper.year}</span>
            &nbsp;-&nbsp;
            <a
              href={paper.link}
              target='_blank'
              rel='noopener noreferrer'
              className='publication__title'
            >
              {paper.title}
            </a>
            <span className='publication__authors'>
              , {parse(paper.authors)}{' '}
            </span>
            <span className='publication__conference'>
              {' '}
              ({paper.conference})
            </span>
          </li>
        ))}
      </ul>
    </section>
  </div>
)

export default Publications
